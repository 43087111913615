
/**
 * Snippets from animate.css
 * Credit goes to https://github.com/daneden
 * github.com/daneden/animate.css
*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/** fadeInRight */
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/** fadeInLeft */
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/** fadeOutRight */
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

/** fadeOutLeft */
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

/** Step Wizard */
.step-wizard {
  position: relative;
  min-height: 220px;
}

.step {
  width: 100%;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
}

.hide {
  display: none;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary.error:hover {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  width: 100%;
  min-width: 200px;
  margin: 10px 5px 10px 0;
  box-shadow: 2px 2px var(--buttonShadow);
  font-size: 12px;
  border: 1px solid var(--buttonBorder);
  padding: 0.5rem;
}

.btn-secondary:hover:not(.error) {
  background-color: var(--primaryColor);
  color: var(--backgroundColor);
}

.btn-sm {
  min-width: 160px !important;
}

.jumbotron {
  padding: 0;
  background-color: var(--jumbotronBackground);
  color: var(--jumbotronColor);
  height: 140px;
  font-size: 18px;
  margin: 0;
  width: 100%;
}
.firstBorder,
.secondBorder {
  height: 10px;
  background-color: var(--firstBorder);
}
.secondBorder {
  background-color: var(--secondBorder);
}
.container-fluid {
  height: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  padding: 0 25px;
}

.subtitle,
.back {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  position: absolute;
  top: 137px;
  margin-left: -33px;
  margin-top: -15px;
  height: 35px;

  background-color: var(--jumbotronColor);
  color: var(--jumbotronBackground);
  font-size: 12px;
  border: 1px solid var(--subtitleBorder);
  border-radius: 6px;
  text-align: center;
}
.subtitle {
  left: 50%;
  width: 70px;
}
.back {
  left: 12%;
  width: 40px;
}
.back:hover {
  cursor: pointer;
  background-color: var(--jumbotronBackground);
  color: var(--jumbotronColor);
  border-color: var(--subtitleHoveredBorder);
}

.bestMortgage-name {
  text-transform: uppercase;
  font-size: 12px;
}

.bestMortgage-tin {
  font-size: 10px;
  top: -8px;
  position: relative;
}

.bestMortgage-monthlyQuota {
  position: relative;
  top: -5px;
}

.bestMortgage-monthlyQuotaAmount {
  font-weight: 800;
  font-size: 22px;
}

.bestMortgage-monthlyQuotaLabel {
  font-weight: 400;
  font-size: 14px;
}

.bestMortgage-btn,
.bestMortgage-btn:active,
.bestMortgage-btn:focus,
.bestMortgage-btn:not(:disabled):not(.disabled):active,
.bestMortgage-btn:not(:disabled):not(.disabled):active:focus,
.btn-secondary.error:hover {
  padding-top: 0.25rem;
  padding-bottom: 0;
}

.btn-secondary.error,
.btn-secondary.error:hover {
  padding: 1rem 2rem;
}

.btn-secondary.error:hover {
  cursor: auto;
}

.bestMortgage-btn .float-right {
  top: -10px;
  position: relative;
}

.spinnerContainer {
  text-align: center;
}

.bestMortgage-comments {
  text-align: center;
  font-size: 12px;
  margin-top: -20px;
  color: rgb(230, 80, 70);
}

.bestMortgage-error {
  text-align: center;
}

.savingsAvailable-error {
  color: rgb(230, 80, 70);
  font-size: 12px;
  text-align: center;
  margin: 5px 0 -14px 0;
}

.content_label {
    font-size: small;
    text-align: center;
    margin-bottom: -8px;
}
.textInput_form,
.textInput_form:focus {
      color: var(--secondaryColor);
      font-size: 13px;
      border: 1px solid var(--secondaryColor);
      text-align: center;
      background-color: var(--backgroundColor);
    }
.content_second {
    margin-top: -17px;
}

@media (min-width: 600px) {
    .wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-align-content: center;
                align-content: center;
    }
    .textInput_form_wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-align-content: center;
                align-content: center;
    }
    .content_second {
        margin-top: 0px;
    } 
    .textInput_form_button {
        max-width: 300px;
    } 
    .content_first {
        margin-right: 10px;
    }
    .textInput-feedback {
        white-space: nowrap;
        font-size: 0.7rem;
    }
    .content_label {
        margin-top: 10px;
    }
}



.content-text {
  text-align: center;
}
.footer {
  color: var(--footerTextColor);
  font-size: 12px;
  text-align: center;
}
.logo {
  width: 85px;
  margin: 0 5px;
}

body {
  font-family: var(--fontFamily), sans-serif;
  background-color: var(--backgroundColor);
}
.col-6 {
  padding: 0 10px;
}
.content {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 50px;
  min-height: 240px;
}
.hidden {
  display: none;
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

.rc-slider {
  margin: 25px 0;
}
.rc-slider-track {
  height: 6px;
  background-image: -webkit-linear-gradient(
    left,
    var(--sliderGradient1),
    var(--sliderGradient2),
    var(--sliderGradient3),
    var(--sliderGradient4)
  );
  background-image: linear-gradient(
    to right,
    var(--sliderGradient1),
    var(--sliderGradient2),
    var(--sliderGradient3),
    var(--sliderGradient4)
  );
}
.rc-slider-handle {
  margin-left: -13px;
  margin-top: -12px;
  width: 28px;
  height: 28px;
  border: none;
  background-color: var(--secondaryColor);
}
.rc-slider-handle::after {
  content: '< >';
  color: var(--jumbotronColor);
  font-size: 10px;
  position: relative;
  left: 6px;
}
.rc-slider-rail {
  background-color: var(--sliderRail);
  height: 6px;
}

.textInput,
.textInput:focus {
  color: var(--secondaryColor);
  font-size: 13px;
  border: 1px solid var(--secondaryColor);
  border-right: none;
  text-align: center;
  background-color: var(--backgroundColor);
}
.input-group-append {
  margin-left: 0;
}
.input-group .textInput {
  padding-left: 2.75rem;
}
.input-group-text {
  font-size: 13px;
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  border-left: none;
  background-color: var(--backgroundColor);
  padding: 0 0.75rem 0 0;
}
.textInput:focus {
  box-shadow: none;
}

