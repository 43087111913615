.content_label {
    font-size: small;
    text-align: center;
    margin-bottom: -8px;
}
.textInput_form,
.textInput_form:focus {
      color: var(--secondaryColor);
      font-size: 13px;
      border: 1px solid var(--secondaryColor);
      text-align: center;
      background-color: var(--backgroundColor);
    }
.content_second {
    margin-top: -17px;
}

@media (min-width: 600px) {
    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
    }
    .textInput_form_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
    }
    .content_second {
        margin-top: 0px;
    } 
    .textInput_form_button {
        max-width: 300px;
    } 
    .content_first {
        margin-right: 10px;
    }
    .textInput-feedback {
        white-space: nowrap;
        font-size: 0.7rem;
    }
    .content_label {
        margin-top: 10px;
    }
}


