.bestMortgage-name {
  text-transform: uppercase;
  font-size: 12px;
}

.bestMortgage-tin {
  font-size: 10px;
  top: -8px;
  position: relative;
}

.bestMortgage-monthlyQuota {
  position: relative;
  top: -5px;
}

.bestMortgage-monthlyQuotaAmount {
  font-weight: 800;
  font-size: 22px;
}

.bestMortgage-monthlyQuotaLabel {
  font-weight: 400;
  font-size: 14px;
}

.bestMortgage-btn,
.bestMortgage-btn:active,
.bestMortgage-btn:focus,
.bestMortgage-btn:not(:disabled):not(.disabled):active,
.bestMortgage-btn:not(:disabled):not(.disabled):active:focus,
.btn-secondary.error:hover {
  padding-top: 0.25rem;
  padding-bottom: 0;
}

.btn-secondary.error,
.btn-secondary.error:hover {
  padding: 1rem 2rem;
}

.btn-secondary.error:hover {
  cursor: auto;
}

.bestMortgage-btn .float-right {
  top: -10px;
  position: relative;
}

.spinnerContainer {
  text-align: center;
}

.bestMortgage-comments {
  text-align: center;
  font-size: 12px;
  margin-top: -20px;
  color: rgb(230, 80, 70);
}

.bestMortgage-error {
  text-align: center;
}
