.jumbotron {
  padding: 0;
  background-color: var(--jumbotronBackground);
  color: var(--jumbotronColor);
  height: 140px;
  font-size: 18px;
  margin: 0;
  width: 100%;
}
.firstBorder,
.secondBorder {
  height: 10px;
  background-color: var(--firstBorder);
}
.secondBorder {
  background-color: var(--secondBorder);
}
.container-fluid {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 25px;
}

.subtitle,
.back {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 137px;
  margin-left: -33px;
  margin-top: -15px;
  height: 35px;

  background-color: var(--jumbotronColor);
  color: var(--jumbotronBackground);
  font-size: 12px;
  border: 1px solid var(--subtitleBorder);
  border-radius: 6px;
  text-align: center;
}
.subtitle {
  left: 50%;
  width: 70px;
}
.back {
  left: 12%;
  width: 40px;
}
.back:hover {
  cursor: pointer;
  background-color: var(--jumbotronBackground);
  color: var(--jumbotronColor);
  border-color: var(--subtitleHoveredBorder);
}
