/** Step Wizard */
.step-wizard {
  position: relative;
  min-height: 220px;
}

.step {
  width: 100%;
  animation-duration: 0.6s;
}

.hide {
  display: none;
}
