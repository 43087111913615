.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary.error:hover {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  width: 100%;
  min-width: 200px;
  margin: 10px 5px 10px 0;
  box-shadow: 2px 2px var(--buttonShadow);
  font-size: 12px;
  border: 1px solid var(--buttonBorder);
  padding: 0.5rem;
}

.btn-secondary:hover:not(.error) {
  background-color: var(--primaryColor);
  color: var(--backgroundColor);
}

.btn-sm {
  min-width: 160px !important;
}
