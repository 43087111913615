body {
  font-family: var(--fontFamily), sans-serif;
  background-color: var(--backgroundColor);
}
.col-6 {
  padding: 0 10px;
}
.content {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  min-height: 240px;
}
.hidden {
  display: none;
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

.rc-slider {
  margin: 25px 0;
}
.rc-slider-track {
  height: 6px;
  background-image: linear-gradient(
    to right,
    var(--sliderGradient1),
    var(--sliderGradient2),
    var(--sliderGradient3),
    var(--sliderGradient4)
  );
}
.rc-slider-handle {
  margin-left: -13px;
  margin-top: -12px;
  width: 28px;
  height: 28px;
  border: none;
  background-color: var(--secondaryColor);
}
.rc-slider-handle::after {
  content: '< >';
  color: var(--jumbotronColor);
  font-size: 10px;
  position: relative;
  left: 6px;
}
.rc-slider-rail {
  background-color: var(--sliderRail);
  height: 6px;
}

.textInput,
.textInput:focus {
  color: var(--secondaryColor);
  font-size: 13px;
  border: 1px solid var(--secondaryColor);
  border-right: none;
  text-align: center;
  background-color: var(--backgroundColor);
}
.input-group-append {
  margin-left: 0;
}
.input-group .textInput {
  padding-left: 2.75rem;
}
.input-group-text {
  font-size: 13px;
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  border-left: none;
  background-color: var(--backgroundColor);
  padding: 0 0.75rem 0 0;
}
.textInput:focus {
  box-shadow: none;
}
